import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Button,
  Switch,
  List,
  Input,
  Select,
  Alert, Image
} from 'antd';
import C2a from './checklisttype/C2a'
import Npd from './checklisttype/Npd'
import Olbig from './checklisttype/Olbig'
import Osa from './checklisttype/Osa'
import Procms from './checklisttype/Procms'
import Prool from './checklisttype/Prool'
import Rental from './checklisttype/Rental'
import Sos from './checklisttype/Sos'
import {
  CheckCircleOutlined, CloseCircleOutlined
} from '@ant-design/icons';
import { green, red } from '@ant-design/colors';
import ReasonList from './ReasonList';
import NumberFormat from 'react-number-format';
function ListItem(props) {
  const [item, setItem] = useState(props.item);
  const [allowSubmitOlbig, setAllowSubmitOlbig] = useState(true);
  let _checklist_item_type = (item) ? item.checklist_type.toLowerCase() : '';
  const formatType = (item.custom_attributes && item.custom_attributes.format_type === 'BIG FORMAT') ? 'BF' : 'MF';
  const [showMsg, setShowMsg] = useState(false);
  const [itemImage, setItemImage] = useState({
    thumbnail_photo_path: '',
    fullscreen_photo_path: '',
  });
  const { Meta } = Card;
  const { Text } = Typography;
  let timeout = null;
  const checklistType = (item.custom_attributes && item.custom_attributes.checklist_type) ? item.custom_attributes.checklist_type.toUpperCase() : (item.checklist_type ? item.checklist_type.toUpperCase() : '');
  // console.log('ReasonList', ReasonList[checklistType]);
  const onChangePass = (id, checked) => {
    if (checked) {
      setItem({ ...item, data: { ...item.data, pass: checked, predefined_qc_note: '' } });
    } else {
      setItem({ ...item, data: { ...item.data, pass: checked } });
    }

  };
  const onChangeReviewPass = (checked) => {
    setItem({ ...item, data: { ...item.data, review_pass: checked } });

  };
  React.useEffect(() => {
    let _itemImage = { ...itemImage };
    if (props?.item?.custom_attributes?.thumbnail_photo_paths?.length) {
      _itemImage.thumbnail_photo_path = props.item.custom_attributes.thumbnail_photo_paths[props.item.custom_attributes.thumbnail_photo_paths.length - 1];
    }
    if (props?.item?.custom_attributes?.fullscreen_photo_paths?.length) {
      _itemImage.fullscreen_photo_path = props.item.custom_attributes.fullscreen_photo_paths[props.item.custom_attributes.fullscreen_photo_paths.length - 1];
    }
    setItemImage(_itemImage);
    setItem(props.item);
  }, [props.item]);
  const onChangeQcNode = (id, e) => {
    setItem({
      ...item,
      data: {
        ...item.data,
        qc_note: e.target.value,
      },
    });
  };
  const onChangeQcNodeOlbig = (position, e) => {
    setItem({
      ...item,
      data: {
        ...item.data,
        qc_note: { ...item.data.qc_note, [position]: e.target.value },
      },
    });
  };
  const onChangeReviewNode = (e) => {
    setItem({
      ...item,
      data: {
        ...item.data,
        review_note: e.target.value,
      },
    });
  };
  const onChangeFinalResult = (e) => {
    setItem({
      ...item,
      data: {
        ...item.data,
        final_result: e.target.value,
      },
    });
  };
  const onChangeFinalResultSOS = (fieldName, value) => {
    setItem({
      ...item,
      data: {
        ...item.data,
        final_result: { ...item.data.final_result, [fieldName]: value },
      },
    });
  };
  const onChangePredefinedQcNodeOlbig = (position, value) => {
    setItem({
      ...item,
      data: {
        ...item.data,
        predefined_qc_note: { ...item.data.predefined_qc_note, [position]: value },
      },
    });
  };
  const onChangePredefinedQcNode = (value) => {
    setItem({
      ...item,
      data: {
        ...item.data,
        predefined_qc_note: value,
      },
    });
  };
  React.useEffect(() => {
    if (_checklist_item_type === 'olbig') {
      if (!item.data.pass) {
        if (!item.data.predefined_qc_note || !Object.keys(item.data.predefined_qc_note).length) {
          console.log('1');
          setAllowSubmitOlbig(false);
        } else {
          console.log('2');
          setAllowSubmitOlbig(true);
        }
      } else {
        console.log('3');
        setAllowSubmitOlbig(true);
      }
    }
  }, [item.data]);
  // setAllowSubmitOlbig
  const submit = async (item) => {

    await props.onSubmit(item);
    setShowMsg(true);
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => { setShowMsg(false) }, 3000);
  };
  /* render using props */
  return <List.Item key={item.id}>
    <Card
      size="small"
      title={<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <span style={{ fontWeight: '700', whiteSpace: 'normal', }}>
          <span style={{
            fontWeight: '700', fontSize: 6,
            display: 'inline-block',
            borderRadius: '50%',
            marginRight: 5,
            width: 15,
            height: 15,
            lineHeight: '15px',
            textAlign: 'center', color: 'white',
            backgroundColor: (formatType === 'MF') ? green[6] : red[6],
          }}>{formatType}</span>
          {(_checklist_item_type === 'osa' && (typeof (item.custom_attributes.new_code) != 'undefined') && (item.custom_attributes.new_code)) ? <span
            style={{ position: 'absolute', right: 0, top: 0, backgroundColor: '#be4d25', padding: '2px 5px', fontSize: 10, color: 'white', display: 'inline-block' }}
          >Old</span> : null}
          {(_checklist_item_type === 'osa' && (typeof (item.custom_attributes.old_code) != 'undefined') && (item.custom_attributes.old_code)) ? <span
            style={{ position: 'absolute', right: 0, top: 0, backgroundColor: '#2587be', padding: '2px 5px', fontSize: 10, color: 'white', display: 'inline-block' }}
          >New</span> : null}
          {item.custom_attributes.stock_name}
        </span>
        <span>
          {item.data && item.data.qc_user_id ? <CheckCircleOutlined title="Đã QC" style={{ color: green[6], fontSize: 20 }} /> : null}

        </span>
        {itemImage.thumbnail_photo_path ? <span>
          <Image
            width={30}
            src="/assets/images/preview.png"
            preview={{
              src: process.env.REACT_APP_API_URL + itemImage.thumbnail_photo_path,
              // src: process.env.REACT_APP_API_URL + (itemImage.fullscreen_photo_path || itemImage.thumbnail_photo_path),
            }}
          />

        </span> : null}
      </div>}
    // actions={[
    //   <Button type="primary" onClick={() => submit(item)}>
    //     Submit
    //   </Button>,
    // ]}
    >
      <Meta
        className="card-meta"

        description={
          <>
            <Row style={{ marginTop: 5, justifyContent: 'space-between' }}>
              <Text style={{ fontWeight: 'bold' }}>Reviewer</Text>
              <Text style={{ fontWeight: 'bold' }}>{item.data.review_user_name || '-'}</Text>
            </Row>
            {/* {(checklistType == 'rental') ?
              <>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Text>Rental ID</Text>
                  <Text>{item.custom_attributes.rental_type_id || ''}</Text>
                </Row>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Text>PNG</Text>
                  <Text>{item.custom_attributes.png_code || ''}</Text>
                </Row>
              </>
              : ''} */}

            {(_checklist_item_type === 'c2a') && <C2a item={item} />}
            {(_checklist_item_type === 'npd') && <Npd item={item} />}
            {(_checklist_item_type === 'olbig') && <Olbig ReasonList={ReasonList && ReasonList[checklistType] && ReasonList[checklistType].length ? ReasonList[checklistType] : []} onChangeQcNodeOlbig={onChangeQcNodeOlbig} onChangePredefinedQcNodeOlbig={onChangePredefinedQcNodeOlbig} hasSubmitFields={!!props.onSubmit} item={item} />}
            {(_checklist_item_type === 'osa') && <Osa item={item} />}
            {(_checklist_item_type === 'procms') && <Procms item={item} />}
            {(_checklist_item_type === 'prool') && <Prool item={item} />}
            {(_checklist_item_type === 'rental') && <Rental item={item} />}
            {(_checklist_item_type === 'sos') && <Sos item={item} />}
            <Row style={{ justifyContent: 'space-between' }}>
              <Text>Passed: </Text>
              {props.isReview ? (item.data.pass ? <CheckCircleOutlined title="Passed" style={{ color: green[6], fontSize: 20 }} /> : <CloseCircleOutlined style={{ color: red[7] }} />) : (props.onSubmit ? <Switch
                // defaultChecked={false}
                checked={item.data.pass}
                onChange={(checked) =>
                  onChangePass(item.id, checked)
                }
              /> : (item.data.pass ? <CheckCircleOutlined style={{ color: green[6] }} /> : <CloseCircleOutlined style={{ color: red[7] }} />))}
            </Row>
            <Row>
              <Col span={24} style={{ paddingTop: 8, paddingBottom: 8 }}>
                {props.isReview ? (!item.data.pass && item.data.predefined_qc_note ? <Row style={{ justifyContent: 'space-between', paddingTop: 3, paddingBottom: 3, borderTop: '1px solid #eaeaea' }}>
                  <Text>Lý do reject</Text>
                  <Text>{item.data.predefined_qc_note}</Text>
                </Row> : null) : props.onSubmit && !item.data.pass && (_checklist_item_type !== 'olbig') ? <Form.Item name="predefined_qc_note" label="Lý do reject" labelCol={{ span: 6 }}>
                  {ReasonList && ReasonList[checklistType] && ReasonList[checklistType].length ? <Select
                    onChange={(value) => onChangePredefinedQcNode(value)}
                    showSearch
                    defaultValue={item.data.predefined_qc_note}
                    placeholder="Chọn lý do"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {ReasonList[checklistType].map((reason, index) => {
                      return <Select.Option style={{ fontSize: 13 }} key={'reason' + index} value={reason.reason}>{reason.title || reason.reason}</Select.Option>
                    })}
                  </Select> : null}
                </Form.Item> : null}
                {props.isReview ? <Row style={{ justifyContent: 'space-between', paddingTop: 1, borderTop: '1px solid #eaeaea' }}>
                  <Text>QC Note</Text>
                  <Text>{item.data.qc_note}</Text>
                </Row> : (props.onSubmit ? ((_checklist_item_type !== 'olbig') ? <Input.Group compact>
                  <Input
                    style={{ width: 'calc(100% - 76px)' }}
                    value={item.data.qc_note}
                    placeholder="Qc note"
                    onChange={(e) => onChangeQcNode(item.id, e)}
                    disabled={props.isReview || false}
                  />
                  <Button type="primary" disabled={!item.data.pass && !item.data.predefined_qc_note} onClick={() => submit(item)}>Submit</Button>

                </Input.Group> : <Button type="primary" disabled={!allowSubmitOlbig} onClick={() => submit(item)}>Submit</Button>) :
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Text>QC Note</Text>
                    <Text>{item.data.qc_note}</Text>
                  </Row>
                )}
                {item && item.data && item.data.qc_user_name ? <Row style={{ marginTop: 5, justifyContent: 'space-between', fontSize: 12 }}>
                  <Text>QC user</Text>
                  <Text style={{ fontWeight: 'bold' }}>{item.data.qc_user_name}</Text>
                </Row> : null}
                {props.isReview ? <div style={{ backgroundColor: '#eaeaea', padding: 5, marginTop: '5px' }}>
                  <Row style={{ justifyContent: 'space-between', paddingTop: 1, marginBottom: 10 }}>
                    <Text>Review pass: </Text>
                    <Switch
                      // defaultChecked={false}
                      checked={item.data.review_pass}
                      onChange={(checked) =>
                        onChangeReviewPass(checked)
                      }
                    />
                  </Row>
                  <Input.Group compact>
                    <Input
                      style={{ width: '100%', marginBottom: 10 }}
                      value={item.data.review_note}
                      placeholder="Review note"
                      onChange={(e) => onChangeReviewNode(e)}
                    />

                  </Input.Group>
                  {_checklist_item_type !== 'sos' ? <Input.Group compact>
                    <Input
                      style={{ width: 'calc(100% - 76px)' }}
                      value={item.data.final_result}
                      placeholder="Final Result"
                      onChange={(e) => onChangeFinalResult(e)}
                    />
                    <Button type="primary" onClick={() => submit(item)}>Submit</Button>
                    {/* isReview={props.isReview} onChangeFinalResultSOS={onChangeFinalResultSOS} */}
                  </Input.Group> : <div>
                    <NumberFormat className="ant-input" style={{ marginBottom: 5 }} placeholder="Final Result - Length of Total" value={item.data.final_result && item.data.final_result['Length of Total'] ? item.data.final_result['Length of Total'] : ''} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                      onValueChange={(values) => {
                        if (values && values.floatValue) {
                          onChangeFinalResultSOS('Length of Total', values.floatValue);
                        } else {
                          onChangeFinalResultSOS('Length of Total', '');
                        }
                      }}
                    />
                    <NumberFormat className="ant-input" style={{ marginBottom: 5 }} placeholder="Final Result - Length of Unilever" value={item.data.final_result && item.data.final_result['Length of Unilever'] ? item.data.final_result['Length of Unilever'] : ''} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                      onValueChange={(values) => {
                        if (values && values.floatValue) {
                          onChangeFinalResultSOS('Length of Unilever', values.floatValue);
                        } else {
                          onChangeFinalResultSOS('Length of Unilever', '');
                        }
                      }}
                    />
                    <NumberFormat className="ant-input" style={{ marginBottom: 5 }} placeholder="Final Result - Length of Export" value={item.data.final_result && item.data.final_result['Length of Export'] ? item.data.final_result['Length of Export'] : ''} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                      onValueChange={(values) => {
                        if (values && values.floatValue) {
                          onChangeFinalResultSOS('Length of Export', values.floatValue);
                        } else {
                          onChangeFinalResultSOS('Length of Export', '');
                        }
                      }}
                    />
                    <NumberFormat className="ant-input" style={{ marginBottom: 5 }} placeholder="Final Result - Length of DOB" value={item.data.final_result && item.data.final_result['Length of DOB'] ? item.data.final_result['Length of DOB'] : ''} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                      onValueChange={(values) => {
                        if (values && values.floatValue) {
                          onChangeFinalResultSOS('Length of DOB', values.floatValue);
                        } else {
                          onChangeFinalResultSOS('Length of DOB', '');
                        }
                      }}
                    />
                    <NumberFormat className="ant-input" style={{ marginBottom: 5 }} placeholder="Final Result - SOS" value={item.data.final_result && item.data.final_result['SOS'] ? item.data.final_result['SOS'] : ''} displayType={'input'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} decimalScale={2}
                      onValueChange={(values) => {
                        if (values && values.floatValue) {
                          onChangeFinalResultSOS('SOS', values.floatValue);
                        } else {
                          onChangeFinalResultSOS('SOS', '');
                        }
                      }}
                    />
                    {/* <Input
                      style={{ width: '100%' }}
                      value={}
                      placeholder="Final Result - Length of Total"
                      onChange={(e) => onChangeFinalResult('Length of Total', e)}
                    />
                    <Input
                      style={{ width: '100%' }}
                      value={item.data.final_result && item.data.final_result['Length of Unilever'] ? item.data.final_result['Length of Unilever'] : ''}
                      placeholder="Final Result - Length of Unilever"
                      onChange={(e) => onChangeFinalResult('Length of Unilever', e)}
                    />
                    <Input
                      style={{ width: '100%' }}
                      value={item.data.final_result && item.data.final_result['Length of Export'] ? item.data.final_result['Length of Export'] : ''}
                      placeholder="Final Result - Length of Export"
                      onChange={(e) => onChangeFinalResult('Length of Export', e)}
                    />
                    <Input
                      style={{ width: '100%' }}
                      value={item.data.final_result && item.data.final_result['Length of DOB'] ? item.data.final_result['Length of DOB'] : ''}
                      placeholder="Final Result - Length of DOB"
                      onChange={(e) => onChangeFinalResult('Length of DOB', e)}
                    />
                    <Input
                      style={{ width: '100%' }}
                      value={item.data.final_result && item.data.final_result['SOS'] ? item.data.final_result['SOS'] : ''}
                      placeholder="Final Result - SOS"
                      onChange={(e) => onChangeFinalResult('SOS', e)}
                    /> */}
                    <Button type="primary" onClick={() => submit(item)}>Submit</Button>
                  </div>}
                </div> : null}
              </Col>
              {showMsg ? <Alert
                message="Submitted"
                type="success"
                showIcon
              /> : null}
            </Row>
          </>
        }
      />
    </Card>
  </List.Item>
}
export default React.memo(ListItem);