import React, { useState } from 'react';

import {
    Row,
    Col,
    Card,
    Table,
    Typography,
    Image,
    Space,
    Form,
    Button,
    DatePicker,
    Tabs,
    Switch,
    List,
    Tree,
    Input,
    Collapse,

} from 'antd';
import ListItem from './Item';
function index(props) {

    return <List
        grid={{
            gutter: 16,
            column: props.itemPerRow,
        }}
        pagination={{
            onChange: page => {
                console.log(page);
            },
            pageSize: 10,
            //   total: __datas.length,
            hideOnSinglePage: true
        }}

        dataSource={props.datas}
        renderItem={(item, index) => {
            return <ListItem isReview={props.isReview || false} onSubmit={(props.submit) ? (item) => props.submit(item) : null} key={item.id + '-' + index} item={item} />
        }}
    />
}
export default React.memo(index);