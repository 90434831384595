import {
  HomeLayout,
  User,
  UploadLayout,
  CheckInCheckOutLayout,
  CheckInCheckOutIndieLayout,
  CheckInCheckOutReviewLayout,
  DownloadLayout,
  ReportDetail,
  ReportOverview,
  ChangePasswordLayout,
  LLVmanager,
  Dashboard,
  ReportDetailLayout,
  AuditProgress,
  AuditStatus,
  AuditStatusMom,
  ActualVsPlan,
  PassAndFail,
  NewDetailKpi,
  AllKpi,
  StoreKpi,
  // DetailKpi,
  AllCustomers,
  AllAuditors,
} from '.';

export default {
  path: '',
  component: HomeLayout,
  role: 'privated',
  childRoutes: [
    { path: 'cico', component: Dashboard, isIndex: true },
    { path: 'in-out', component: CheckInCheckOutLayout, isIndex: true },
    { path: 'in-out-indie', component: CheckInCheckOutIndieLayout, isIndex: true }, //
    { path: 'in-out-review', component: CheckInCheckOutReviewLayout, isIndex: true }, //
    { path: 'user', component: User },
    { path: 'upload', component: UploadLayout },
    { path: 'changepassword', component: ChangePasswordLayout },
    { path: 'llv-manager', component: LLVmanager },
    { path: 'download', component: DownloadLayout },
    { path: 'report-detail', component: ReportDetail },
    { path: 'detail-report', component: ReportDetailLayout },
    { path: 'report-overview', component: ReportOverview },
    { path: 'audit-progress', component: AuditProgress },
    { path: 'audit-status', component: AuditStatus },
    { path: 'audit-status-mom', component: AuditStatusMom },
    { path: 'actual-plan', component: ActualVsPlan },
    { path: 'pass-and-fail', component: PassAndFail },
    // { path: 'osa-npd-pro', component: OsaNpdPro },
    { path: 'all-kpi', component: AllKpi },
    { path: 'store-kpi', component: StoreKpi },
    { path: 'detail-kpi', component: NewDetailKpi },
    { path: 'perf-all-customers', component: AllCustomers },
    { path: 'perf-all-auditors', component: AllAuditors },
  ],
};
